import React, { Component } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import Honeybadger from "@honeybadger-io/js";
import { Provider as StateProvider, Subscribe } from "unstated";

import Home from "./Home";
import DoctorReview from "./DoctorReview";

import Promotions from "./Promotions";

import Single from "./Single";
import Profile from "./Profile";
import ActivationSuccess from "./ActivationSuccess";
import ActivationError from "./ActivationError";
import NotFound from "../components/NotFound";
import Onboarding from "./Onboarding";
import MainLayout from "../layouts/MainLayout";
import OnboardingLayout from "../layouts/OnboardingLayout";
import EmptyLayout from "../layouts/EmptyLayout";
import OrdersContainer from "./OrdersContainer";
import { Flipper } from "../flipper";
import ErrorBoundary from "../components/ErrorBoundary";
import env from "../env";
import { initHoneybadger } from "../helpers";
import i18n from "../localization/i18n";
import { Query, QueryClient, QueryClientProvider } from "@tanstack/react-query";

const SecurityContext = React.createContext({});

const RouteWithLayout = ({
  component: Component,
  layout: Layout,
  bloodTestEnabled,
  ...rest
}) => (
  <SecurityContext.Consumer>
    {(value) => (
      <Route
        {...rest}
        render={(props) => (
          <Layout
            {...value}
            {...(bloodTestEnabled && { bloodTestEnabled: bloodTestEnabled })}
          >
            <Component
              {...props}
              currentUser={value.current_user}
              {...(bloodTestEnabled && { bloodTestEnabled: bloodTestEnabled })}
            />
          </Layout>
        )}
      />
    )}
  </SecurityContext.Consumer>
);

const queryClient = new QueryClient();

const selectedLanguage = i18n.language;
const orders = new OrdersContainer();

const changeLanguage = (user) => {
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const userLanguage = user.locale_preference;

  if (selectedLanguage == undefined && userLanguage != undefined) {
    localStorage.setItem("selectedLanguage", userLanguage);
    i18n.changeLanguage(userLanguage);
    window.location.reload(false);
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bloodTestEnabled: false,
    };
  }

  componentDidMount() {
    const currentUser = this.props.current_user;
    Flipper.setFeatures(this.props.features);
    changeLanguage(currentUser);

    initHoneybadger({ component: "application" });

    if (currentUser) {
      Honeybadger.setContext({
        user_id: currentUser.id,
        user_email: currentUser.email,
      });

      orders.loadList(selectedLanguage);
      if (!this.state.bloodTestEnabled)
        this.setState({ bloodTestEnabled: true });
    }
  }

  render() {
    const { bloodTestEnabled } = this.state;

    const profileDisabled = env.PROFILE_DISABLED === "true";

    const rootComponent = () => {
      return (
        <Subscribe to={[orders]}>
          {(orders) => {
            if (
              bloodTestEnabled &&
              orders.state.list &&
              orders.state.list.length > 0 &&
              !orders.state.active
            ) {
              return (
                <Redirect to={`/orders/${orders.state.list[0].order_number}`} />
              );
            }
            return null;
          }}
        </Subscribe>
      );
    };

    return (
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <StateProvider inject={[orders]}>
              <SecurityContext.Provider value={this.props}>
                {bloodTestEnabled && (
                  <Route
                    exact
                    path={"/orders/:id"}
                    render={(props) => {
                      const isActive =
                        orders.state.active &&
                        orders.state.active.order_number ===
                          props.match.params.id;
                      if (!isActive) {
                        orders.loadOrder({
                          id: props.match.params.id,
                          lang: selectedLanguage,
                        });
                      }
                      return null;
                    }}
                  />
                )}
                <Route exact path={"/"} render={rootComponent} />
                <Route exact path={"/orders"} render={rootComponent} />
                <Switch>
                  <RouteWithLayout
                    path={"/promotions"}
                    layout={MainLayout}
                    component={Promotions}
                    bloodTestEnabled={bloodTestEnabled}
                    {...this.props}
                  />
                  <RouteWithLayout
                    exact
                    path={"/"}
                    layout={MainLayout}
                    component={Home}
                    bloodTestEnabled={bloodTestEnabled}
                    {...this.props}
                  />
                  <RouteWithLayout
                    exact
                    path={"/doctor-review"}
                    layout={MainLayout}
                    component={DoctorReview}
                    bloodTestEnabled={bloodTestEnabled}
                    {...this.props}
                  />

                  <RouteWithLayout
                    path={"/orders/:orderId/results/:id"}
                    layout={MainLayout}
                    component={Single}
                    {...this.props}
                  />
                  <RouteWithLayout
                    path={"/orders/:id"}
                    layout={MainLayout}
                    component={Home}
                    bloodTestEnabled={bloodTestEnabled}
                    {...this.props}
                  />
                  {!profileDisabled && (
                    <RouteWithLayout
                      path={"/profile"}
                      layout={MainLayout}
                      component={Profile}
                      bloodTestEnabled={bloodTestEnabled}
                      {...this.props}
                    />
                  )}
                  <RouteWithLayout
                    path={"/activate_success"}
                    layout={MainLayout}
                    component={ActivationSuccess}
                    {...this.props}
                  />
                  <RouteWithLayout
                    path={"/activate_error"}
                    layout={MainLayout}
                    component={ActivationError}
                    {...this.props}
                  />
                  <RouteWithLayout
                    path={"/onboarding"}
                    layout={OnboardingLayout}
                    component={Onboarding}
                    {...this.props}
                  />
                  <RouteWithLayout
                    layout={EmptyLayout}
                    component={NotFound}
                    {...this.props}
                  />
                </Switch>
              </SecurityContext.Provider>
            </StateProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
