/* eslint no-console:0 */
import "core-js/fn/array/flat-map";
import WebpackerReact from "webpacker-react";

import "./app.scss";

import App from "../src/containers/App.js";
import Login from "../src/containers/Login.js";
import LoginStaging from "../src/containers/LoginStaging.js";
import LoginAuth from "../src/containers/LoginAuth.js";
import LoginSweden from "../src/containers/LoginSweden.js";
import LoginStagingSweden from "../src/containers/LoginStagingSweden.js";
import Maintenance from "../src/containers/Maintenance.js";

import { initHoneybadger } from "../src/helpers";

initHoneybadger({ component: "application" });

WebpackerReact.setup({
  App,
  Login,
  LoginAuth,
  LoginStaging,
  LoginSweden,
  LoginStagingSweden,
  Maintenance,
});
