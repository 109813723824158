/* eslint-disable no-undef */

module.exports = {
  APP_ID: __APP_ID,
  APP_NAME: __APP_NAME,
  ENVIRONMENT: __ENVIRONMENT,
  HONEYBADGER_API_KEY: __HONEYBADGER_API_KEY,
  HONEYBADGER_ENV: __HONEYBADGER_ENV || __ENVIRONMENT,
  HONEYBADGER_REVISION: __GIT_COMMIT_SHA,
  DEFAULT_LOCALE: __DEFAULT_LOCALE,
  PROFILE_COUNTRY: __PROFILE_COUNTRY,
  USER_CHANGE_DISABLED: __USER_CHANGE_DISABLED,
  WEBSHOP_URL: __WEBSHOP_URL,
  PROMOTIONS_ENABLED: __PROMOTIONS_ENABLED,
  PROFILE_DISABLED: __PROFILE_DISABLED,
  LOCALIZATION_ALLOWED_LOCALES: __LOCALIZATION_ALLOWED_LOCALES,
  WOOCOMMERCE_ENDPOINT: __WOOCOMMERCE_ENDPOINT,
};
