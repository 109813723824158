import React, { Component } from "react";

import LoginFooter from "../components/LoginFooter";
import Logo from "../components/Logo";

import { Lock, Info } from "react-feather";
import EmptyLayout from "../layouts/EmptyLayout";
import SuggestionSection from "./../components/SuggestionSection";
import ErrorBoundary from "./../components/ErrorBoundary";

import { withTranslation } from "react-i18next";

class LoginSweden extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedOut: false,
    };
  }

  componentDidMount() {
    if (window.location.search && window.location.search === "?logout") {
      this.setState({ loggedOut: true });
    }
  }

  renderLoginForm(t) {
    return (
      <div>
        <React.Fragment>
          <div className="flex justify-center margin-1 margin-bottom">
            <div className="icon-badge bg-purple">
              <Lock />
            </div>
          </div>
          <div className="margin-1 margin-bottom child-margins-y-0-25 text-align-center">
            <h1 className="size-large color-purple weight-500">
              {t("login.secure_login_title")}
            </h1>
            <p>{t("login.secure_login_description")}</p>
          </div>

          <div className="margin-1 margin-top">
            <a
              className="button button--block justify-center"
              href="/grandid_auth"
            >
              {t("login.secure_login_link")}
            </a>
          </div>

          <div className="margin-1 margin-top child-margins-y-0-25 text-align-center size-small">
            <p>{t("login.secure_login_note")}</p>
          </div>
        </React.Fragment>
      </div>
    );
  }

  renderNotice(t) {
    return (
      <div className="margin-1 margin-bottom">
        <div className="split-card bg-white flex">
          <div className="padding-0-75 bg-blue flex align-center justify-center">
            <Info className="stroke-white width-1 height-1" />
          </div>
          <div className="padding-0-75">
            {this.state.loggedOut ? (
              <p className="weight-500 color-purple">
                {t("login.you_are_logged_out")}{" "}
                <a
                  href="/"
                  className="link"
                  onClick={(event) => {
                    event.preventDefault();
                    this.setState({ loggedOut: false });
                  }}
                >
                  {t("login.from_here")}
                </a>
              </p>
            ) : (
              <>
                <span className="weight-500 color-purple">
                  {t("login.welcome_to_puhti")}{" "}
                </span>
                {t("login.when_results_are_ready")}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <ErrorBoundary>
        <EmptyLayout>
          <div className="padding-1">
            {this.state.loggedOut ? (
              <div className="padding-2 padding-top">
                <div className="logo flex justify-center margin-1 margin-bottom">
                  <Logo />
                </div>
                {this.renderNotice(t)}
              </div>
            ) : (
              <>
                {this.renderNotice(t)}
                <div className="split-card">
                  <div className="split-card__left bg-purple">
                    <Logo
                      logoType="symbol"
                      className={`login-logo ${
                        this.state.loading && "login-logo--loading"
                      }`}
                    />
                  </div>
                  <div className="split-card__right">
                    {this.renderLoginForm(t)}
                  </div>
                </div>
                <LoginFooter features={this.props.features} />
              </>
            )}
          </div>
          {this.state.loggedOut && (
            <>
              <div className="margin-2 margin-top">
                <SuggestionSection activeOrder />
              </div>
            </>
          )}
        </EmptyLayout>
      </ErrorBoundary>
    );
  }
}
export default withTranslation()(LoginSweden);
