import React, { Component } from "react";
import { CheckCircle, AlertCircle } from "react-feather";

import {
  calculateValues,
  valueHasSmallerThanSign,
  valueHasLargerThanSign,
  parseDisplayValue,
} from "../helpers";
import { withTranslation } from "react-i18next";

// function PositiveOrNegativeMessage({ positiveMessage, negativeMessage }) {
//   if (!positiveMessage || !negativeMessage) {
//     return null;
//   }
//   const className = positiveMessage
//     ? "margin-1-5 margin-top inline-icon-text color-green"
//     : "margin-1-5 margin-top inline-icon-text color-red";
//   return (
//     <div className={className}>
//       {positiveMessage ? (
//         <CheckCircle className="inline-icon-text__icon" />
//       ) : (
//         <AlertCircle className="inline-icon-text__icon" />
//       )}
//       <div className="inline-icon-text__text">
//         {positiveMessage ? positiveMessage : negativeMessage}
//       </div>
//     </div>
//   );
// }
class RangeChart extends Component {
  calculateBarDimensions(
    highestValue,
    lowestValue,
    value,
    referenceHigh,
    referenceLow,
    recommendationLow,
    recommendationHigh,
  ) {
    const totalRange = highestValue - lowestValue;

    const valuePosition = (100 * (value - lowestValue)) / totalRange;
    const maxRPercentage = (100 * (referenceHigh - lowestValue)) / totalRange;

    const redBarWidthFirst = (
      (100 * (referenceLow - lowestValue)) /
      totalRange
    ).toFixed(4);
    const greenBarWidth = (maxRPercentage - redBarWidthFirst).toFixed(4);
    const redBarWidthSecond = (100 - redBarWidthFirst - greenBarWidth).toFixed(
      4,
    );
    let yellowBarWidthFirst = 0;
    let yellowBarWidthSecond = 0;
    if (recommendationLow) {
      yellowBarWidthFirst =
        ((recommendationLow - referenceLow) / (referenceHigh - referenceLow)) *
        100;
    }

    if (recommendationHigh) {
      yellowBarWidthSecond =
        100 -
        ((recommendationHigh - referenceLow) / (referenceHigh - referenceLow)) *
          100;
    }

    return {
      valuePosition,
      redBarWidthFirst,
      greenBarWidth,
      redBarWidthSecond,
      yellowBarWidthFirst,
      yellowBarWidthSecond,
    };
  }

  renderReferenceMessage(
    displayValue,
    value,
    referenceLow,
    referenceHigh,
    recommendationLow,
    recommendationHigh,
    recommendedValueDescription,
    t,
  ) {
    const isMinMaxValue =
      valueHasLargerThanSign(displayValue) ||
      valueHasSmallerThanSign(displayValue);

    if (
      (recommendationLow && value <= recommendationLow) ||
      (recommendationHigh && value >= recommendationHigh)
    ) {
      return (
        <div className="note">
          {/*todo: should come from admin */}
          {recommendedValueDescription && recommendedValueDescription}
          {isMinMaxValue && t("range_chart.result_is_minmax")}
        </div>
      );
    } else if (value >= referenceLow && value <= referenceHigh) {
      return (
        <>
          <div className="inline-icon-text color-green">
            <CheckCircle className="inline-icon-text__icon" />
            <div className="inline-icon-text__text">
              {t("range_chart.result_inside_reference_values")}
            </div>
          </div>
          {isMinMaxValue && (
            <div className="inline-icon-text margin-0-5 margin-top">
              <div className="inline-icon-text__text">
                {isMinMaxValue && t("range_chart.result_is_minmax")}
              </div>
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <div className="inline-icon-text color-red">
            <AlertCircle className="inline-icon-text__icon" />
            <div className="inline-icon-text__text">
              {t("range_chart.result_outside_reference_values")}
            </div>
          </div>
          {isMinMaxValue && (
            <div className="inline-icon-text margin-0-5 margin-top">
              <div className="inline-icon-text__text">
                {isMinMaxValue && t("range_chart.result_is_minmax")}
              </div>
            </div>
          )}
        </>
      );
    }
  }

  render() {
    const {
      value,
      displayValue,
      referenceLow,
      referenceHigh,
      lowestValue,
      highestValue,
      hideLow,
      hideUpper,
    } = calculateValues(
      this.props.value,
      this.props.referenceLow,
      this.props.referenceHigh,
    );

    const {
      recommendationLow,
      recommendationHigh,
      recommendedValueDescription,
      t,
    } = this.props;
    const {
      valuePosition,
      redBarWidthFirst,
      greenBarWidth,
      redBarWidthSecond,
      yellowBarWidthFirst,
      yellowBarWidthSecond,
    } = this.calculateBarDimensions(
      highestValue,
      lowestValue,
      value,
      referenceHigh,
      referenceLow,
      recommendationLow,
      recommendationHigh,
    );

    return (
      <div>
        <div className="range-slider">
          <div
            className="range-slider__unit"
            style={{ left: valuePosition + "%" }}
          >
            {this.props.microbiome
              ? Number.parseFloat(this.props.value).toFixed(5).replace(".", ",")
              : parseDisplayValue(displayValue, this.props.t)}{" "}
            {this.props.unit}
          </div>
          {!valueHasSmallerThanSign(displayValue) &&
            !valueHasLargerThanSign(displayValue) && (
              <div
                className="range-slider__indicator"
                style={{ left: valuePosition + "%" }}
              />
            )}
          <div
            className={
              this.props.microbiome
                ? "range-slider__bar range-slider__microbiome-color"
                : "range-slider__bar bg-red"
            }
            style={{ width: redBarWidthFirst + "%" }}
          />
          <div
            className="range-slider__bar bg-green"
            style={{ width: greenBarWidth + "%" }}
          >
            {recommendationLow && (
              <div
                className="range-slider__recommendation range-slider__recommendation--low"
                data-value={Number.parseFloat(recommendationLow).toFixed()}
                style={{ width: yellowBarWidthFirst + "%" }}
              />
            )}

            <div className="range-slider__min">
              {!hideLow &&
                (this.props.microbiome
                  ? Number.parseFloat(referenceLow).toFixed(5).replace(".", ",")
                  : referenceLow)}
            </div>
            <div className="range-slider__max">
              {!hideUpper &&
                (this.props.microbiome
                  ? Number.parseFloat(referenceHigh)
                      .toFixed(5)
                      .replace(".", ",")
                  : referenceHigh)}
            </div>

            {recommendationHigh && (
              <div
                className="range-slider__recommendation range-slider__recommendation--high"
                data-value={Number.parseFloat(recommendationHigh).toFixed()}
                style={{ width: yellowBarWidthSecond + "%" }}
              />
            )}
          </div>
          <div
            className={
              this.props.microbiome
                ? "range-slider__bar range-slider__microbiome-color"
                : "range-slider__bar bg-red"
            }
            style={{ width: redBarWidthSecond + "%" }}
          />
        </div>
        {!this.props.microbiome && (
          <div className={`margin-1-5 margin-top`}>
            {!this.props.hideText &&
              this.renderReferenceMessage(
                displayValue,
                value,
                referenceLow,
                referenceHigh,
                recommendationLow,
                recommendationHigh,
                recommendedValueDescription,
                t,
              )}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(RangeChart);
